import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/Template.js";
import Divider from 'src/components/Divider';
import AnalyticsScript from 'src/components/Analytics';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`FAQs`}</h1>
    <hr></hr>
    <h3>{`So what exactly is this…?`}</h3>
    <p><strong parentName="p">{`Meme Insider`}</strong>{` has joined forces with `}<strong parentName="p">{`Know Your Meme`}</strong>{` to utilize and optimize both Meme Insider’s journalism and Know Your Meme’s breadth and extensive meme knowledge to start producing more content with even higher quality. The Meme Insider team is not changing, and we hope to be able to grow our team exponentially in the next year.`}</p>
    <Divider mdxType="Divider" />
    <h3>{`What is changing?`}</h3>
    <p>{`Nothing of the core experience and vision of Meme Insider is changing. Yet, our partnership with KYM will allow us to both expand the size of the Magazine, as well as enrich the content itself. You should expect larger magazines, as well as various insights, graphics, and quantitative Meme information. In the near future, we also plan to expand our coverage to Daily reporting of Meme Journalism, which is basically coverage of trends and events from the lenses of Memes and Internet culture.`}</p>
    <p>{`Right now, the only thing that Meme Insider produces is the monthly magazine, the content of which will not change, This partnership is just so we can start producing content in addition to the magazine, such as daily articles (similar to our old “flash articles”).`}</p>
    <Divider mdxType="Divider" />
    <h3>{`Can I join the Meme Insider team?`}</h3>
    <p>{`Yes! We are revamping the process for adding writers, designers, and artists to our volunteer team. You can go to `}<a parentName="p" {...{
        "href": "/positions"
      }}>{`memeinsider.com/positions`}</a>{` for more information.`}</p>
    <Divider mdxType="Divider" />
    <h3>{`How do I get print copies?`}</h3>
    <p>{`We are introducing a brand new system, called “Meme Insider Premium” where users can pay \\$7.69 a month to get a hand delivered print Meme Insider every month, as well as exclusive Premium only content. Find out more at `}<a parentName="p" {...{
        "href": "/subscribe"
      }}>{`memeinsider.com/subscribe`}</a></p>
    <AnalyticsScript mdxType="AnalyticsScript" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      