import React from 'react';

import Layout from 'src/layout/Layout';
import SEO from 'src/components/global/seo';

const Post = ({ children, pageContext }) => {
  const { title } = pageContext.frontmatter;

  return (
    <Layout className="post">
      <SEO {...(title ? { title } : {})} />
      <article>{children}</article>
    </Layout>
  );
};

export default Post;
